/* Fix position and z-index of Contact Us button */
.contact-us-button {
    position: fixed;
    bottom: 2rem; /* Adjust as needed */
    right: 2rem; /* Adjust as needed */
    background-color: black; /* Button background */
    color: white;
    border-radius: 50%;
    padding: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    z-index: 100; /* High enough to stay above other elements */
  }
  
  /* Contact Us Slide-Out Panel */
  .contact-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 20rem; /* Adjust width as needed */
    height: 100vh; /* Full height */
    background-color: white; /* Panel background */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(100%); /* Initially hidden */
    transition: transform 0.3s ease;
    z-index: 200; /* Higher than button and other elements */
  }
  
  .contact-panel.show {
    transform: translateX(0); /* Show panel */
  }
  